<template>
  <v-card elevation="0">
    <v-row>
      <v-col :cols="!expand ? '7' : '5'">
        <v-card :disabled="shipment.shippingStatusId === 9" color="white" tile class="elevation-0">
          <!--            Maritmo-->
          <div v-if="shipment.transportationId === 1">
            <v-card-text>
              <v-card-title>
                <h4>Maritimo</h4>
              </v-card-title>
              <v-row class="mt-0">
                <v-col cols="3">
                  <v-autocomplete
                      label="Embarcador (agente en origen)"
                      class="pt-4"
                      v-model="shipin"
                      :search-input.sync="search"
                      dense
                      item-value="id"
                      :items="provedoress"
                      item-text="name"
                      return-object
                      clearable
                      @keydown.delete="provedoress = []"
                      @click="provedoress = []"
                      @click:clear="cleanEmbarcador"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                      label="Consignatario  (Nosotros)"
                      class="pt-4"
                      v-model="consig"
                      dense
                      :search-input.sync="consigSearch"
                      item-value="id"
                      :items="itemsConsig"
                      item-text="name"
                      return-object
                      clearable
                      @keydown.delete="itemsConsig = []"
                      @click="itemsConsig = []"
                      @click:clear="cleanConsig"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                                  label="Naviera"
                                  v-model="shipment.masterBl.shippingLine.supplierId"
                                  class="pt-4"
                                  dense
                                  item-text="companyName"
                                  item-value="id"
                                  :items="suppliers"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                  v-model="shipment.landTransfer"
                  label="Transbordo"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.masterBl.refNumber"
                      label="M B/L"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.masterBl.freeDays"
                      label="Dias Libres"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="itemsCarga[0].containerNumber"
                      label="Num Contenedor"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="itemsCarga[0].containerSeals"
                      label="Num Sellos"
                  ></v-text-field>
                </v-col>
                <v-col cols="1.5">
                  <v-text-field
                      v-model="itemsCarga[0].description"
                      label="Descripcion"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.masterBl.bookingNumber"
                      label="Booking Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.masterBl.shipName"
                      label="Vessel Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.masterBl.tripNumber"
                      label="Voyage Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="mt-5">
                  <v-autocomplete
                      label="Incoterm"
                      item-value="id"
                      item-text="code"
                      v-model="shipment.incotermId"
                      dense
                      :items="incoterms"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.commodity"
                      label="Commodity"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.referenceNumber"
                      label="Orden Cliente"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <file-drag
                      v-model="masterFiles"
                      @change="ConvertFile()">
                  </file-drag>
                </v-col>
              </v-row>
            </v-card-text>
              <template>
                <v-card-title>
                  Carga
                </v-card-title>
                <v-data-table
                  :headers="getHeaders(shipment.transportationcategoryId)"
                  :items="itemsCarga"
                  hide-default-footer>
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.containerId="{item}">
                  <v-autocomplete
                      v-model="item.containerId"
                      label="Tipo"
                      single-line
                      autofocus
                      item-text="description"
                      item-value="id"
                      :items="itemsContainers"
                  ></v-autocomplete>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="Volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.piecesPerPallet="{item}">
                  <v-text-field
                      v-model="item.piecesPerPallet"
                      label="Piezas por Pallet"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.width="{item}">
                  <v-text-field
                      v-model="item.width"
                      label="Ancho"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.height="{item}">
                  <v-text-field
                      v-model="item.height"
                      label="Altura"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.large="{item}">
                  <v-text-field
                      v-model="item.large"
                      label="Largo"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.action="{ item, index }">
                  <v-btn
                      icon
                      color="error"
                      small
                      v-if="itemsCarga.length > 1"
                      @click="remove(item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </div>
          <!--            Aereo master-->
          <div v-if="shipment.transportationId === 2">
            <v-card-text>
              <v-card-title>
                <h4>Aereo</h4>
              </v-card-title>
              <v-row class="mt-0">
                <v-col cols="3">
                  <v-autocomplete
                      label="Embarcador (agente en origen)"
                      class="pt-4"
                      v-model="shipin"
                      :search-input.sync="search"
                      dense
                      item-value="id"
                      :items="provedoress"
                      item-text="name"
                      return-object
                      clearable
                      @keydown.delete="provedoress = []"
                      @click="provedoress = []"
                      @click:clear="cleanEmbarcador"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                      label="Consignatario  (Nosotros)"
                      class="pt-4"
                      v-model="consig"
                      dense
                      :search-input.sync="consigSearch"
                      item-value="id"
                      :items="itemsConsig"
                      item-text="name"
                      return-object
                      @keydown.delete="itemsConsig = []"
                      @click="itemsConsig = []"
                      @click:clear="cleanConsig"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                      label="Aerolinea"
                      v-model="shipment.masterBl.airline.supplierId"
                      class="pt-4"
                      dense
                      item-text="companyName"
                      item-value="id"
                      :items="suppliers"
                      @change="getBookings"
                  ></v-autocomplete>
                </v-col><v-col cols="3">
                <v-checkbox
                    v-model="shipment.landTransfer"
                    label="Transbordo"
                ></v-checkbox>
              </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="shipment.masterBl.refNumber"
                      label="Guia MAWB"
                      @click="txt2select = !txt2select"
                      v-if="!txt2select"
                  ></v-text-field>
                  <v-select :items="BooginsRanges" v-if="txt2select && shipment.orderTypeId === 2" label="Guia MAWB"  v-model="shipment.masterBl.refNumber"></v-select>
                  <v-text-field
                      v-model="shipment.masterBl.refNumber"
                      label="Guia MAWB"
                      v-if="txt2select && shipment.orderTypeId === 1"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="itemsCarga[0].containerSeals"
                      label="Num Guias"
                  ></v-text-field>
                </v-col>
                <v-col cols="1.5">
                  <v-text-field
                      v-model="itemsCarga[0].description"
                      label="Descripcion"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.masterBl.tripNumber"
                      label="Numero de Vuelo"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="mt-5">
                  <v-autocomplete
                      label="Incoterm"
                      item-value="id"
                      item-text="code"
                      v-model="shipment.incotermId"
                      dense
                      :items="incoterms"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.commodity"
                      label="Commodity"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="shipment.referenceNumber"
                      label="Orden Cliente"
                  ></v-text-field>
                </v-col>

              </v-row>

              <v-row>
                <v-col cols="12">
                  <file-drag
                      v-model="masterFiles"
                      @change="ConvertFile()">
                  </file-drag>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>
              Carga
            </v-card-title>
            <template>
              <v-data-table
                  :headers="loadingHeadersPalles"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="Volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.piecesPerPallet="{item}">
                  <v-text-field
                      v-model="item.piecesPerPallet"
                      label="Piezas por Pallet"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.width="{item}">
                  <v-text-field
                      v-model="item.width"
                      label="Ancho"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.height="{item}">
                  <v-text-field
                      v-model="item.height"
                      label="Altura"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.large="{item}">
                  <v-text-field
                      v-model="item.large"
                      label="Largo"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      v-if="itemsCarga.length > 1"
                      @click="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </div>
          <!--Terrestre (no house)-->
          <div v-if="shipment.transportationId === 3">
            <v-card-text>
              <v-card-title>
                <h4>Terrestre</h4>
              </v-card-title>
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                      label="Linea Terrestre"
                      v-model="shipment.masterBl.shippingLine.supplierId"
                      class="pt-4"
                      dense
                      item-text="companyName"
                      item-value="id"
                      :items="suppliers"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3" offset="6">
                  <v-text-field
                      label="Conocimiento"
                      v-model="shipment.masterBl.refNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="3">
                  <v-autocomplete
                      label="Embarcador"
                      v-model="shipment.masterBl.houseBls[0].mEmbarcador"
                      :search-input.sync="shipment.masterBl.houseBls[0].mSearch"
                      item-text="companyName"
                      item-value="id"
                      clearable
                      return-object
                      @click="emOrCon(0, 1)"
                      :items="shipment.masterBl.houseBls[0].mItems"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                      label="Consignatario"
                      v-model="shipment.masterBl.houseBls[0].cConsig"
                      :search-input.sync="shipment.masterBl.houseBls[0].cSearch"
                      item-text="companyName"
                      item-value="id"
                      clearable
                      return-object
                      @click="emOrCon(0, 2)"
                      @click:append-outer="emOrCon(0, 2)"
                      @click:append="emOrCon(0, 2)"
                      :items="shipment.masterBl.houseBls[0].cItems"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="shipment.commodity"
                      label="Commodity"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="Orden Cliente"
                      v-model="shipment.referenceNumber"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                      v-model="shipment.landTransfer"
                      label="Transbordo"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      label="Num Contenedor"
                      v-model="itemsCarga[0].containerNumber"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      label="Num Placas"
                      v-model="itemsCarga[0].containerSeals"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      label="Descripcion"
                      v-model="itemsCarga[0].description"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      label="Num Camion"
                      v-model="itemsCarga[0].truckNumber"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <file-drag v-model="masterFiles" @change="ConvertFile()">
                  </file-drag>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>
              Carga
            </v-card-title>
            
            <template>
              <v-data-table
                  :headers="getHeaders(shipment.transportationcategoryId)"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.containerId="{item}">
                  <v-autocomplete
                      v-model="item.containerId"
                      label="Tipo"
                      single-line
                      autofocus
                      item-text="description"
                      item-value="id"
                      :items="itemsContainers"
                  ></v-autocomplete>
                </template>

                <template v-slot:item.piecesPerPallet="{item}">
                  <v-text-field
                      v-model="item.piecesPerPallet"
                      label="Piezas por Pallet"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="Volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.width="{item}">
                  <v-text-field
                      v-model="item.width"
                      label="Ancho"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.height="{item}">
                  <v-text-field
                      v-model="item.height"
                      label="Altura"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.large="{item}">
                  <v-text-field
                      v-model="item.large"
                      label="Largo"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      v-if="itemsCarga.length > 1"
                      @click="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </div>
          <!--            Maritimo house-->
          <div v-if="shipment.transportationId === 1">
            <v-tabs v-model="housTabs">
              <v-tab
                  v-for="(tab, index) in shipment.masterBl.houseBls"
                  :key="index"
              >
                House {{ tab.refNumber ? tab.refNumber : tab.id }}
                <v-btn icon class="ml-1" @click="removeTab(index)">
                  <v-icon size="15" color="red">mdi-close</v-icon>
                </v-btn>
              </v-tab>
              <template>
                <v-btn v-if="shipment.masterBl.houseBls.length == 0" small color="primary" @click="addTab">Agregar House</v-btn>
                <v-btn v-else icon color="primary" @click="addTab">
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
            </v-tabs>
            <v-tabs-items v-model="housTabs">
              <v-tab-item
                  v-for="(tab, index) in shipment.masterBl.houseBls"
                  :key="index"
              >
                <v-card flat>
                  <v-card-text class="pt-0">
                    <br/>
                    <v-row class="mt-0">
                      <v-col cols="3">
                        <v-autocomplete
                            label="Embarcador"
                            class="pt-4"
                            v-model="tab.mEmbarcador"
                            :search-input.sync="tab.mSearch"
                            @click="emOrCon(index, 1)"
                            dense
                            item-text="companyName"
                            item-value="id"
                            clearable
                            return-object
                            :items="tab.mItems"
                            @keydown.delete="tab.mFirst = false"
                            @click:clear="cleanEmbarcadorH(index)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                            label="Consignatario"
                            class="pt-4"
                            v-model="tab.cConsig"
                            :search-input.sync="tab.cSearch"
                            @click="emOrCon(index, 2)"
                            dense
                            item-text="companyName"
                            item-value="id"
                            clearable
                            return-object
                            :items="tab.cItems"
                            @keydown.delete="tab.cFirst = false"
                            @click:clear="cleanEmbarcadorH2(index)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" offset="1">
                      </v-col>
                      <v-col cols="2" offset="1">
                        <v-text-field
                            v-model="tab.refNumber"
                            label="H B/L*"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-autocomplete
                            label="Tipo de BL"
                            class="pt-4"
                            dense
                            item-text="name"
                            item-value="id"
                            v-model="tab.blTypeId"
                            :items="BlType"
                        ></v-autocomplete>
                      </v-col>
                      <!--                      <v-col cols="3">
                                              <v-autocomplete
                                                  v-model="tab.customAgentId"
                                                  :items="CustomAgents"
                                                  item-value="id"
                                                  item-text="name"
                                                  label="Agente aduanal"
                                              ></v-autocomplete>
                                            </v-col>-->
                      <v-col cols="4" class="ml-3">
                        <v-checkbox
                            v-model="tab.showAsAgreed"
                            label="As Agreed?"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <file-drag
                            v-model="houseFiles"
                            @change="ConvertFile2(index)"
                        ></file-drag>
                      </v-col>
                    </v-row>


                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <!--            Aereo house-->
          <div v-if="shipment.transportationId === 2">
            <v-tabs v-model="housTabs">
              <v-tab
                  v-for="(tab, index) in shipment.masterBl.houseBls"
                  :key="index"
              >
                House {{ tab.refNumber ? tab.refNumber : tab.id }}
                <v-btn icon class="ml-1" @click="removeTab(index)">
                  <v-icon size="15" color="red">mdi-close</v-icon>
                </v-btn>
              </v-tab>
              <template>
                <v-btn v-if="shipment.masterBl.houseBls.length == 0" small color="primary" @click="addTab">Agregar House</v-btn>
                <v-btn v-else icon color="primary" @click="addTab">
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
            </v-tabs>
            <v-tabs-items v-model="housTabs">
              <v-tab-item
                  v-for="(tab, index) in shipment.masterBl.houseBls"
                  :key="index"
              >
                <v-card flat>
                  <v-card-text class="pt-0">
                    <br/>
                    <v-row class="mt-0">
                      <v-col cols="3">
                        <v-autocomplete
                            label="Embarcador"
                            class="pt-4"
                            v-model="tab.mEmbarcador"
                            :search-input.sync="tab.mSearch"
                            @click="emOrCon(index, 1)"
                            dense
                            item-text="companyName"
                            item-value="id"
                            clearable
                            return-object
                            :items="tab.mItems"
                            @keydown.delete="tab.mFirst = false"
                            @click:clear="cleanEmbarcadorH(index)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                            label="Consignatario"
                            class="pt-4"
                            v-model="tab.cConsig"
                            :search-input.sync="tab.cSearch"
                            @click="emOrCon(index, 2)"
                            dense
                            item-text="companyName"
                            item-value="id"
                            clearable
                            return-object
                            :items="tab.cItems"
                            @keydown.delete="tab.cFirst = false"
                            @click:clear="cleanEmbarcadorH2(index)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3">
<!--                        <v-text-field
                            v-model="tab.bookingNumber"
                            label="Orden Cliente"
                        ></v-text-field>-->
                      </v-col>
                      <v-col cols="2" offset="1">
                        <v-text-field
                            v-model="tab.refNumber"
                            label="Guia House"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                            label="Tipo de BL"
                            class="pt-4"
                            dense
                            item-text="name"
                            item-value="id"
                            v-model="tab.blTypeId"
                            :items="BlType"
                        ></v-autocomplete>
                      </v-col>
<!--                      <v-col cols="3">
                        <v-autocomplete
                            v-model="tab.customAgentId"
                            :items="CustomAgents"
                            item-value="id"
                            item-text="name"
                            label="Agente aduanal"
                        ></v-autocomplete>
                      </v-col>-->
                      <v-col cols="4" class="ml-3">
                        <v-checkbox
                            v-model="tab.showAsAgreed"
                            label="As Agreed?"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <file-drag
                            v-model="houseFiles"
                            @change="ConvertFile2(index)"
                        ></file-drag>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>

          <div>
            <v-row>
              <v-col cols="12" class="mb-4">
                <v-btn
                    dense
                    outlined
                    block
                    color="primary"
                    @click="updateShipment"
                >
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!--            no hay hose en terrestre-->
        </v-card>
      </v-col>
      <v-col :cols="expand ? '7' : '5'">
        <div>
          <v-alert
              type="success"
              dense
              border="left"
              style="padding-top: 0px; padding-bottom: 0px"
              v-application--is-ltr
              v-if="final"
          >
            <v-row>
              <v-col cols="9">
                <h3 class="mt-1">Finalizado</h3>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn class="mt-2" icon elevation="0" @click="problem = true">
                <v-icon>mdi-alert</v-icon>
              </v-btn>
            </v-row>
            <div></div>
          </v-alert>
        </div>
        <v-row>
          <v-col cols="6">
            <v-combobox
                multiple
                readonly
                small-chips
                :items="losNotifys"
                label="Notifys"
                hide-selected
                v-model="losNotifys"
            >
              <template>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Escribe un correo y presiona <kbd>enter</kbd> para
                      agregarlo.
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-text-field
            label="Referencia SoftCargo"
            v-model="shipment.externalRefNumber"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-expansion-panels class="pt-5" focusable multiple
        >
          <v-expansion-panel>
            <!-- <v-expansion-panel-header dense
            >
              <v-row no-gutters>
                  Carga
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    :color="expand ? 'red' : 'green'"
                    small
                    @click.stop.native
                    @click="expand = !expand"
                >
                  <v-icon v-if="!expand">mdi-arrow-expand-horizontal</v-icon>
                  <v-icon v-else>mdi-arrow-collapse-horizontal</v-icon>
                </v-btn>
                <v-btn
                    icon
                    color="primary"
                    small
                    @click="addCargo"
                    @click.stop.native
                    v-if="itemsCarga.length === 0"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </v-row>
            </v-expansion-panel-header> -->
            <!-- <v-expansion-panel-content v-if="shipment.transportationId === 1 && shipment.transportationcategoryId === 11">
              <v-data-table
                  :headers="headersMaritimo"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template
                    v-slot:item.containerId="{item}">
                  <v-autocomplete
                      v-model="item.containerId"
                      label="Tipo"
                      single-line
                      autofocus
                      item-text="description"
                      item-value="id"
                      :items="itemsContainers"
                  ></v-autocomplete>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="Num contenedor"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Num contenedor"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.containerNumber="{item}">
                  <v-btn icon @click="ContendorFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.containerSeals="{item}">
                  <v-btn icon @click="SellosFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.description="{item}">
                  <v-btn icon @click="descriptionDialog(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action="{ item, index }">
                  <v-btn
                      icon
                      color="error"
                      small
                      @click="remove(item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-col cols="12">
                <v-btn
                    elevation="2"
                    block
                    @click="updateCargo"
                    color="error"
                >Guardar
                </v-btn>
              </v-col>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="shipment.transportationId === 1 && shipment.transportationcategoryId === 9">
              <v-data-table
                  :headers="loadingHeadersPallesM"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.piecesPerPallet="{item}">
                  <v-text-field
                      v-model="item.piecesPerPallet"
                      label="Piezas por Pallet"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.containerSeals="{item}">
                  <v-btn icon @click="ContendorFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.description="{item}">
                  <v-btn icon @click="descriptionDialog(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action2="{item}">
                  <v-btn icon @click="openDialogMedidas(item)" >
                    <v-icon color="primary">mdi-perspective-less</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      @click="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-col cols="12">
                <v-btn
                    elevation="2"
                    block
                    @click="updateCargo"
                    color="error"
                >Guardar
                </v-btn>
              </v-col>
            </v-expansion-panel-content> -->
            <!-- <v-expansion-panel-content v-if="shipment.transportationId === 2">
              <v-data-table
                  :headers="loadingHeadersPalles"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.piecesPerPallet="{item}">
                  <v-text-field
                      v-model="item.piecesPerPallet"
                      label="Piezas por Pallet"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.containerSeals="{item}">
                  <v-btn icon @click="GuiasModal(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.description="{item}">
                  <v-btn icon @click="descriptionDialog(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.medidas="{item}">
                  <v-btn icon @click="openDialogMedidas(item)">
                    <v-icon color="primary">mdi-perspective-less</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      @click="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-col cols="12">
                <v-btn
                    elevation="2"
                    block
                    @click="updateCargo"
                    color="error"
                >Guardar
                </v-btn>
              </v-col>
            </v-expansion-panel-content> -->
            <!-- <v-expansion-panel-content v-if="shipment.transportationId === 3 && shipment.transportationcategoryId === 2 || shipment.transportationcategoryId === 3">
              <v-data-table
                  :headers="loadingHeadersT"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template
                    v-slot:item.containerId="{item}">
                  <v-autocomplete
                      v-model="item.containerId"
                      label="Caja"
                      single-line
                      autofocus
                      item-text="description"
                      item-value="id"
                      :items="itemsContainers"
                  ></v-autocomplete>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>
                <template v-slot:item.truckNumber="{item}">
                  <v-btn icon @click="TruckFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.containerNumber="{item}">
                  <v-btn icon @click="ContendorFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.containerSeals="{item}">
                  <v-btn icon @click="SellosFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.description="{item}">
                  <v-btn icon @click="descriptionDialog(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      @click="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-col cols="12">
                <v-btn
                    elevation="2"
                    block
                    @click="updateCargo"
                    color="error"
                >Guardar
                </v-btn>
              </v-col>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="shipment.transportationId === 3 && shipment.transportationcategoryId === 1">
              <v-data-table
                  :headers="loadingHeadersPallesT"
                  :items="itemsCarga"
                  hide-default-footer
              >
                <template v-slot:item.quantity="{item}">
                  <v-text-field
                      v-model="item.quantity"
                      label="Qty"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>
                <template v-slot:item.piecesPerPallet="{item}">
                  <v-text-field
                      v-model="item.piecesPerPallet"
                      label="Piezas por Pallet"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.weight="{item}">
                  <v-text-field
                      v-model="item.weight"
                      label="Peso"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>

                <template v-slot:item.volumen="{item}">
                  <v-text-field
                      v-model="item.volumen"
                      label="volumen"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.palletsPerContainer="{item}">
                  <v-text-field
                      v-model="item.palletsPerContainer"
                      label="Pallets"
                      single-line
                      autofocus
                  ></v-text-field>
                </template>


                <template v-slot:item.truckNumber="{item}">
                  <v-btn icon @click="TruckFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.containerNumber="{item}">
                  <v-btn icon @click="ContendorFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>


                <template v-slot:item.containerSeals="{item}">
                  <v-btn icon @click="SellosFimc(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.description="{item}">
                  <v-btn icon @click="descriptionDialog(item)">
                    <v-icon color="primary">mdi-table</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.medidas="{item}">
                  <v-btn icon @click="openDialogMedidas(item)">
                    <v-icon color="primary">mdi-perspective-less</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      @click="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      @click="addCargo"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-col cols="12">
                <v-btn
                    elevation="2"
                    block
                    @click="updateCargo"
                    color="error"
                >Guardar
                </v-btn>
              </v-col>
            </v-expansion-panel-content> -->


          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header dense
            >Servicios
              <v-spacer/>
              <v-btn
                  icon
                  color="primary"
                  small
                  @click.stop.native
                  @click="addServicio()"
                  v-if="services.length === 0"
                  style="max-width: 24px"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                  dense
                  :headers="headerServices"
                  :items="services"
                  style="padding:0px"
              >
                <template v-slot:item.service.name="{ item }">
                  <v-select
                  :items="itemsServicios"
                  v-model="item.serviceId"
                  item-value="id"
                  label="Servicio"
                  item-text="name"
                  @click="item"
                  >
                  </v-select>
                </template>
                <template v-slot:item.isConfirmed="{ item }">
                  <v-checkbox
                  v-model="item.isConfirmed"
                  color="primary"
                  >
                  </v-checkbox>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      icon
                      color="error"
                      small
                      @click="deleteServicio(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      color="primary"
                      small
                      v-if="item.isLast"
                      @click="addServicio()"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header dense
            >Documentos
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col md="10">
                    <v-treeview
                        v-model="fileTree"
                        :items="fileStructure"
                        activatable
                        item-text="fileName"
                        return-object
                        open-on-clickahh
                    >
                      <template v-slot:prepend="{ item, open }">
                        <v-icon @click="downloadDocument(item)" v-if="!item.fileExtension">
                          {{ open ? "mdi-folder-open" : "mdi-folder" }}
                        </v-icon>
                        <v-icon v-else @click="downloadDocument(item)">
                          {{ fileTypes[item.fileExtension.toLowerCase()] }}
                        </v-icon>
                      </template>
                      <template v-slot:label="{ item }">
                        <div>
                            <span @click="downloadDocument(item)">{{ item.fileName }}</span
                            ><span v-if="item.fileExtension"
                        >.{{ item.fileExtension.toLowerCase() }}</span
                        >
                        </div>
                      </template>
                      <template v-slot:append="{ item, open }">
                        <v-icon @click="deleteDoc(item, open)" v-if="item.fileExtension" color="red">
                          mdi-minus
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header dense
            >Informacion general del embarque
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <span v-if="shipment.comments">
                      <b>Comentarios:</b> {{shipment.comments}}
                    </span>
                    <span v-else>
                      Comentarios:
                    </span>
                  </v-col>
                  <v-col v-if="shipment.users.length > 0" cols="6" class="mt-2 d-flex justify-start flex-nowrap ml-2">
                    <span v-if="shipment.users.findIndex(x => x.user.roles[0].roleId === 1) >= 0">
                    <b class="mr-1">Vendedor: </b>
                        {{shipment.users[shipment.users.findIndex(x => x.user.roles[0].roleId === 1)].user.firstName + " " + shipment.users[shipment.users.findIndex(x => x.user.roles[0].roleId === 1)].user.lastName}}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2  justify-start ml-2">
                    <div v-if="shipment.orderType.description">
                      <b>Tipo:</b> {{shipment.orderType.description}}
                    </div>
                    <div v-else>
                      Tipo:
                    </div>
                  </v-col>
                  <v-col v-if="shipment.users.length > 0" cols="6" class="mt-2 d-flex justify-start flex-nowrap ml-2">
                    <span v-if="shipment.users.findIndex(x => x.user.roles[0].roleId === 3) >= 0">
                    <b class="mr-1">Operador: </b>
                      {{shipment.users[shipment.users.findIndex(x => x.user.roles[0].roleId === 3)].user.firstName + " " + shipment.users[shipment.users.findIndex(x => x.user.roles[0].roleId === 3)].user.lastName}}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.transportationCategory.description">
                      <b>Servicio:</b> {{shipment.transportationCategory.description}}
                    </div>
                    <div v-else>
                      Tipo:
                    </div>
                  </v-col>
                  <v-col v-if="shipment.users.length > 0" cols="6" class="mt-2 d-flex justify-start flex-nowrap ml-2">
                    <span v-if="shipment.users.findIndex(x => x.user.roles[0].roleId === 2) >= 0">
                    <b class="mr-1">Pricer: </b>
                      {{shipment.users[shipment.users.findIndex(x => x.user.roles[0].roleId === 2)].user.firstName + " " + shipment.users[shipment.users.findIndex(x => x.user.roles[0].roleId === 2)].user.lastName}}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.referenceNumber">
                      <b>Referencia cliente:</b> {{shipment.referenceNumber}}
                    </div>
                    <div v-else>
                      Referencia cliente:
                    </div>
                  </v-col>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.emails">
                      <b>Correos de contacto:</b> {{shipment.emails}}
                    </div>
                    <div v-else>
                      Referencia cliente:
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.clientSupplierName">
                      <b>Nombre de empresa:</b> {{shipment.clientSupplierName}}
                    </div>
                    <div v-else>
                      Nombre de empresa:
                    </div>
                  </v-col>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.contactName">
                      <b>Nombre de contacto:</b> {{shipment.contactName}}
                    </div>
                    <div v-else>
                      Nombre de contacto:
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.cargo.length > 0">
                      <b>Peligroso:</b> {{shipment.cargo[0].dangerous}}
                    </div>
                  </v-col>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.cargo.length > 0">
                    <b>Dentro o fuera:</b> {{shipment.cargo[0].landFull}}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.cargo.length > 0">
                    <b>Estibable:</b> {{shipment.cargo[0].stackable}}
                    </div>
                  </v-col>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div>
                      <b>Commodity:</b> {{shipment.commodity}}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div>
                      <b>Industira:</b> {{shipment.industry.description}}
                    </div>
                  </v-col>
                  <v-col cols="5" class="mt-2 d-flex justify-start ml-2">
                    <div v-if="shipment.cargo.length > 0">
                    <b>Precio de mercacía:</b> {{shipment.cargo[0].goodsCost}}
                    </div>
                  </v-col>
                </v-row>

              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="descriptionLoadingDialog" max-width="1000">

      <v-card>
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-table-edit</v-icon>
            Descripción
          </h2>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="descriptionLoadingDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row dense justify="center">
            <v-col md="12">
              <v-textarea
                  outlined
                  class="mt-6"
                  name="input-7-4"
                  v-model="dataCargo.description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-btn
                elevation="2"
                block
                @click="updateCargo"
                color="error"
            >Guardar
            </v-btn>
          </v-col>
        </v-card-text>

      </v-card>


    </v-dialog>
    <v-dialog v-model="ContendorDialog" max-width="1000">

      <v-card>
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-table-edit</v-icon>
            Numero de Contenedores
          </h2>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="ContendorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col md="12" class="mt-10">
              <v-combobox multiple
                          dense
                          small-chips
                          label="Identificador de contenedores"
                          hide-selected
                          v-model="dataCargo.containerNumber"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Escribe un numero <kbd>enter</kbd> para agregarlo.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

          </v-row>

        </v-card-text>

        <v-col cols="12">
          <v-btn
              elevation="2"
              block
              @click="ContendorDialog = false"
              color="error"
          >Guardar
          </v-btn>
        </v-col>

      </v-card>


    </v-dialog>
    <v-dialog v-model="TruckDialog" max-width="1000">
      <v-card>
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-table-edit</v-icon>
            Numero de camiones
          </h2>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="TruckDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col md="12" class="mt-10">
              <v-combobox multiple
                          dense
                          small-chips
                          label="Identificador de contenedores"
                          hide-selected
                          v-model="dataCargo.truckNumber"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Escribe un numero <kbd>enter</kbd> para agregarlo.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

          </v-row>

        </v-card-text>

        <v-col cols="12">
          <v-btn
              elevation="2"
              block
              @click="TruckDialog = false"
              color="error"
          >Guardar
          </v-btn>
        </v-col>

      </v-card>
    </v-dialog>
    <v-dialog v-model="sellosDialog" max-width="1000">

      <v-card>
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-table-edit</v-icon>
            Numero de sellos
          </h2>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="sellosDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col md="12" class="mt-10">
              <v-combobox multiple
                          dense
                          small-chips
                          label="Identificador de sellos"
                          hide-selected
                          v-model="dataCargo.containerSeals"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Escribe un numero <kbd>enter</kbd> para agregarlo.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

          </v-row>

        </v-card-text>

        <v-col cols="12">
          <v-btn
              elevation="2"
              block
              @click="sellosDialog = false"
              color="error"
          >Guardar
          </v-btn>
        </v-col>

      </v-card>


    </v-dialog>
    <v-dialog v-model="GuiasDialog" max-width="1000">

      <v-card>
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-table-edit</v-icon>
            Numero de Guias
          </h2>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="GuiasDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col md="12" class="mt-10">
              <v-combobox multiple
                          dense
                          small-chips
                          label="Identificador de sellos"
                          hide-selected
                          v-model="dataCargo.containerSeals"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Escribe un numero <kbd>enter</kbd> para agregarlo.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

          </v-row>

        </v-card-text>

        <v-col cols="12">
          <v-btn
              elevation="2"
              block
              @click="updateBultos"
              color="error"
          >Guardar
          </v-btn>
        </v-col>

      </v-card>


    </v-dialog>
    <v-dialog v-model="medidasDialog" max-width="1000">
      <v-toolbar app dark class="primary white--text">
        <h2 class="font-weight-regular">
          <v-icon size="25">mdi-perspective-less</v-icon>
          Medidas
        </h2>
        <v-spacer></v-spacer>

        <v-btn icon dark @click="medidasDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row>
            <v-text-field
                label="Width"
                v-model="dataCargo.width"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                label="Height"
                v-model="dataCargo.height"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                label="Large"
                v-model="dataCargo.large"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-col cols="12">
          <v-btn
              elevation="2"
              block
              @click="updateCargo"
              color="primary"
          >Actualizar
          </v-btn>
        </v-col>

      </v-card>


    </v-dialog>


  </v-card>
</template>

<script>
import FileDrag from "@/components/Global/FileDrag/FileDrag";
// import OrderSearchBar from "@/components/Global/OrderSearchBar/OrderSearchBar";
// import SenStatusBar from "@/views/Pages/Operation/components/SenStatusBar";
// import SenContainers from "@/views/Pages/Operation/components/SenContainers";
// import SenRouteTimeline from "@/views/Pages/Operation/components/SenRouteTimeline";
import debouncePromise from "debounce-promise";
import fileDownload from "js-file-download";
// import {downloadS3} from "@/composables/downloadS3";

export default {
  name: "masterBl",
  components: {
    FileDrag,
  },
  props: {
    updateNotyClient: null,
    updateSelectedOperator:null,
    shipment: {
      type: Object,
    },
  },
  data: (vm) => ({
    expand: false,
    txt2select: false,
    dWidth: null,
    dHeigh: null,
    medidasDialog: false,
    losNotifys: [],
    isGuardar: false,
    typeCon: '',
    typeEM: '',
    itemsConsig: [{}],
    BooginsRanges: [],
    consig: null,
    provedoress: [{}],
    itemsServicios: [],
    services: [],
    GuiasDialog: false,
    TruckDialog:false,
    shipin: {},
    sellosDialog: false,
    documentsM:null,
    documentsH:[],
    itemsCarga: [],
    itemsNaviera: [],
    navieraReady: false,
    itemConsignatarios: [],
    emails2: "",
    emails: "",
    test: null,
    dataCargo: {},
    search: null,
    containerNumber: null,
    containerSeals: null,
    truckNumber: null,
    ContendorDialog: false,
    trnasId: 0,
    itemsContainers: [],
    housTabs: null,
    descriptionLoadingDialog: false,
    descriptionData: null,
    headerServices: [
      {
        text: 'id',
        value: "id"
      },
      {
      text: 'Nombre',
      value: "service.name"
      },
      {
        text: 'Confirmado',
        value: "isConfirmed"
      },
      {
        text: "",
        value: "action",
      },
    ],
    headersMaritimo: [
      {
        text: "Cantidad",
        align: "start",
        sortable: false,
        value: "quantity",
        width: "50px"
      },
      {
        text: "Contenedor",
        sortable: false,
        value: "containerId",
        width: "200px"
      },
      {
        text: "Pallets",
        sortable: false,
        value: "palletsPerContainer",
        width: "150px"
      },
      {
        text: "Volumen(m³)",
        sortable: false,
        value: "volumen",
        width: "150px"
      },
      {
        text: "Peso(Kg)",
        sortable: false,
        value: "weight",
        width: "150px"
      },
      {
        text: "",
        value: "action",
      },
    ],
    loadingHeadersT: [
      {
        text: "Cantidad",
        align: "start",
        sortable: false,
        value: "quantity",
        width: "100px",
      },
      {
        text: "Caja",
        sortable: false,
        value: "containerId",
        width: "150px",
      },
      {
        text: "Pallets",
        sortable: false,
        value: "palletsPerContainer",
        width: "150px",
      },
      {
        text: "Volumen",
        sortable: false,
        value: "volumen",
        width: "150px",
      },
      {
        text: "Peso",
        sortable: false,
        value: "weight",
        width: "150px",
      },
      {
        text: "",
        value: "action",
      },
    ],
    loadingHeadersPallesM: [
      //debe de ir en bultos, no en quantity (quantity en palets siempre debe de ir 0 (porque son 0 contenedores))
      {
        text: "Cantidad",
        align: "start",
        sortable: false,
        value: "quantity",
        width: "50px"
      },
      {
        text: "Pallets",
        sortable: false,
        value: "palletsPerContainer",
        width: "100px"
      },
      {
        text: "Piezas por Pallet",
        sortable: false,
        value: "piecesPerPallet",
        width: "100px"
      },
      {
        text: "Peso",
        sortable: false,
        value: "weight",
        width: "100px"
      },
      {
        text: "Volumen(m³)",
        sortable: false,
        value: "volumen",
        width: "100px"
      },
      {
        text: "Ancho",
        sortable: false,
        value: "width"
      },
      {
        text: "Altura",
        sortable: false,
        value: "height"
      },
      {
        text: "Largo",
        sortable: false,
        value: "large"
      },
      {
        text: "",
        value: "action",
      },
    ],
    loadingHeadersPalles: [
      //debe de ir en bultos, no en quantity (quantity en palets siempre debe de ir 0 (porque son 0 contenedores))
      {
        text: "Pallets",
        sortable: false,
        value: "quantity",
        width: "100px",
      },
      {
        text: "Piezas por Pallet",
        sortable: false,
        value: "piecesPerPallet",
        width: "150px"
      },
      {
        text: "Peso",
        sortable: false,
        value: "weight",
        width: "150px",
      },
      {
        text: "Volumen(m³)",
        sortable: false,
        value: "volumen",
        width: "150px",
      },
      {
        text: "Ancho",
        sortable: false,
        value: "width"
      },
      {
        text: "Altura",
        sortable: false,
        value: "height"
      },
      {
        text: "Largo",
        sortable: false,
        value: "large"
      },
      {
        text: "",
        value: "action",
      },
    ],
    loadingHeadersPallesT: [
      //debe de ir en bultos, no en quantity (quantity en palets siempre debe de ir 0 (porque son 0 contenedores))
      {
        text: "Cantidad",
        sortable: false,
        value: "quantity",
        width: "50px"
      },
      {
        text: "Pallets",
        sortable: false,
        value: "palletsPerContainer",
        width: "100px"

      },
      {
        text: "Piezas por Pallet",
        sortable: false,
        value: "piecesPerPallet",
        width: "100px"

      },
      {
        text: "Peso",
        sortable: false,
        value: "weight",
        width: "100px"
      },
      {
        text: "Volumen",
        sortable: false,
        value: "volumen",
        width: "150px"
      },
      {
        text: "Ancho",
        sortable: false,
        value: "width"
      },
      {
        text: "Altura",
        sortable: false,
        value: "height"
      },
      {
        text: "Largo",
        sortable: false,
        value: "large"
      },
      {
        text: "",
        value: "action",
      },
    ],
    final: false,
    fileTree: null,
    fileTypes: {
      pdf: "mdi-file-pdf-box",
      png: "mdi-file-image",
      jpg: "mdi-file-image",
      jpeg: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },
    acceptedFiles: ["png", "jpg", "jpeg", "gif", "tiff", "svg", "pdf"],
    imageTypes: ["png", "jpg", "jpeg", "gif", "tiff", "svg"],
    fileStructure: [
      {
        fileName: "Documentos",
        children: [],
      },
    ],
    problem: false,
    switch1: true,
    singleSelect: false,
    selected: [],
    headerss: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "name",
      },
    ],
    desserts: [
      {
        name: "Almacenaje",
      },
      {
        name: "Seguro",
      },
    ],

    length: 1,
    details: [],
    Currencies: [],
    Consignees: [],
    expanded: [],
    tab: null,
    e6: 1,
    masterBl: {
      shipper: null, //Embarcador (nesario mandar la id)
      consignee: null, //Todavia no existe
      supplierId: null,
      bookingNumber: null,
      shipName: null,
      tripNumber: null,
      delaysInPort: null,
      storageDays: null,
      Mails: "",
      freeDays: 0
    },
    House: {
      consignee: null,
      shipper: null,
      Naviera: null,
      customAgentId: null,
      bookingNumber: null,
      Tipo: null,
      customAgent: null,
      AgenteAduanal: null,
    },
    headersParent: [
      {
        text: "Supplier",
        align: "left",
        value: "supplier.name",
      },
    ],

    headersChild: [
      {
        text: "Select",
        align: "left",
        value: "data-table-select",
        class: "checkbox",
        cellClass: "checkbox",
      },
      {
        text: "Cargo",
        align: "left",
        value: "name",
      },
      {
        text: "Currency",
        align: "left",
        value: "currency.name",
      },
      {
        text: "Quantity",
        align: "left",
        value: "quantity",
      },
      {
        text: "Price",
        align: "left",
        value: "price",
      },
      {
        text: "Pallets",
        sortable: false,
        value: "palletsPerContainer",
      },
      {
        text: "Profit",
        align: "left",
        value: "profit",
      },
      {
        text: "Total",
        align: "left",
        value: "total",
      },
      {
        text: "Status",
        align: "left",
        value: "status",
      },
      {
        text: "RET",
        align: "left",
        value: "hasRet",
      },
      {
        text: "IVA",
        align: "left",
        value: "hasVat",
      },
      {
        text: "accion",
        align: "left",
        value: "botones",
      },
    ],

    headersChildsales: [
      {
        text: "Select",
        align: "left",
        value: "data-table-select",
        class: "checkbox",
        cellClass: "checkbox",
      },
      {
        text: "Cargo",
        align: "left",
        value: "name",
      },
      {
        text: "Currency",
        align: "left",
        value: "currency.name",
      },
      {
        text: "Quantity",
        align: "left",
        value: "quantity",
      },
      {
        text: "Price",
        align: "left",
        value: "price",
      },
      {
        text: "Profit",
        align: "left",
        value: "profit",
      },
      {
        text: "Total",
        align: "left",
        value: "total",
      },

      {
        text: "accion",
        align: "left",
        value: "botones",
      },
    ],

    shipping: {},
    items2: [
      {
        action: "mdi-ticket",
        items: [{title: "Documento"}, {title: "Documento"}],
        title: "Informacion sobre solicitud",
      },
      {
        action: "mdi-ticket",
        items: [{title: "PDF de venta"}, {title: "Documento"}],
        title: "Servicos",
      },
      {
        action: "mdi-ticket",
        items: [{title: "PDF de venta"}, {title: "Documento"}],
        title: "Venta",
      },
      {
        action: "mdi-school",
        items: [{title: "Documento de compra"}],
        title: "Compra",
      },
    ],

    items3: [
      {
        action: "mdi-ticket",
        items: [{title: "Documento"}, {title: "Documento"}],
        title: "Solcitud de pago",
      },
      {
        action: "mdi-ticket",
        items: [{title: "PDF de venta"}, {title: "Documento"}],
        title: "Servicos",
      },
    ],
    initiallyOpen: ["public"],
    files2: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },
    prices: [],
    companies: [],
    BlType: [
      {
        id: 1,
        name: "Origin",
      },
      {
        id: 2,
        name: "Telex",
      },
      {
        id: 3,
        name: "Swb",
      },
    ],
    tree: [],
    forwarders: [],
    itemsF: [
      {
        name: "Factura.CMA",
      },
      {
        name: "Factura.Trans",
      },
    ],
    IdOrder: null,
    time: null,
    menu2: false,
    modal2: false,

    opciones: ["H/BL", "M/BL"],

    date: null,
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    // New
    events: [],
    input: null,
    nonce: 0,
    houseFiles: [],
    masterFiles: [],
    alerts: [
      {
        name: "Recolección",
        date: "2021/05/01",
        text: null,
      },
      {
        name: "Llego a POL",
        date: "2021/05/01",
        text: null,
      },
    ],
    defaultAlert: {
      name: null,
      date: null,
      text: null,
    },
    confimedCharges: false,
    chargesWorking: false,
    demoCargos: [
      {
        cargo: "",
        moneda: "",
        iva: "",
        ivaret: "",
        cantidad: 1,
        compra: 600,
        peso: 600,
        venta: 1000,
        profit: 400,
      },
    ],
    defaultCosto: {
      cargo: "",
      moneda: "",
      iva: "",
      ivaret: "",
      cantidad: "",
      compra: "",
      peso: "",
      venta: "",
      profit: "",
    },
    suppliers: [],
    shipmentIsValid: true,
    shipmentWorking: false,
    requiredRule: [(v) => !!v || "Este campo es obligatorio"],
    requiredSelectRule: [(v) => v.length > 0 || "Este campo es obligatorio"],
    items: [
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        title: "Proveedro",
      },
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        title: "Cliente",
      },
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        title: "Provedor de cliente",
      },
    ],

    itemsB: [
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        titleB: "Booking: 31931101221",
      },
    ],

    emailValid: [
      (v) => {
        if (!v || v.length < 1) return "Debes agregar al menos un correo";
        else if (!v || v.length > 0) {
          for (let i = 0; i < v.length; i++) {
            if (!/.+@.+\..+/.test(v[i])) {
              return "Un correo no es valido.";
            }
          }
        }
        return true;
      },
    ],
    searchEmails: null,
    shippingStatus: [
      {
        id: 1,
        name: "Provisional",
      },
      {
        id: 2,
        name: "En tránsito",
      },
      {
        id: 3,
        name: "Arribados",
      },
      {
        id: 4,
        name: "Detenido",
      },
      {
        id: 5,
        name: "Entregados",
      },
      {
        id: 6,
        name: "Cerrados",
      },
    ],
    ogStatus: [
      {
        id: 1,
        name: "Provisional",
      },
      {
        id: 2,
        name: "En tránsito",
      },
      {
        id: 3,
        name: "Arribados",
      },
      {
        id: 4,
        name: "Detenido",
      },
      {
        id: 5,
        name: "Entregados",
      },
      {
        id: 6,
        name: "Cerrados",
      },
    ],
    shipment: {
      id: null,
      orderId: null,
      transportationId: null,
      incoterm: null,
      incotermId: null,
      users: [],
      pol: null,
      pod: null,
      origin: null,
      destination: null,
      shippingStatusId: null,
      orderFolio: null,
      containers: [],
      emails: [],
      routes: [],
      supplier: null,
    },

    inconterms: [],
    operators: [],
    isFirstSearch: true,
    isFirstSearch2: true,
    // End New
    facturaDrawer: false,
    dialogReclamo: false,
    paymentDrawer: false,
    showSolicitud: false,
    filesDrag: [],
    selectedCargo: null,
    defaultCharge: {
      cargo: null,
      moneda: "USD",
      iva: false,
      ivaret: false,
      cantidad: 1,
      compra: 0,
      peso: 0,
      venta: 0,
      profit: 0,
      editedIndex: -1,
      detail: [],
    },
    charges: [],
    temporaryDocs: [],
    headers: [
      {
        text: "Numero",
        value: "cargo",
      },
      {
        text: "Conenedor",
        value: "moneda",
      },
      {
        text: "Pallets",
        value: "compra",
      },
      {
        text: "Volumen",
        value: "Profit",
      },
      {
        text: "Peso",
        value: "peso",
      },

      {
        text: "",
        value: "action",
      },
      {
        text: "Descripicon",
        value: "cargo",
      },
      {
        text: "",
        value: "action",
      },
    ],
    editOrCreate: false,
    // Quitar
    files: [
      "File 01.pdf",
      "File 02.pdf",
      "File 03.pdf",
      "File 04.pdf",
      "File 05.pdf",
      "File 06.pdf",
      "File 07.pdf",
      "File 08.pdf",
      "File 09.pdf",
      "File 10.pdf",
    ],
    // End Quitar
    show: false,
    consigSearch: null,
    searchHouse: null,
    indexHouse: null,
    isFirstSearchHouse: false,
    isCon: null,
    mini: !vm.$vuetify.breakpoint.xl,
    justChanged: true,
  }),
  watch: {
    updateSelectedOperator: {
      handler(){

        this.updateShipment(this.updateSelectedOperator)
      }
    },
    updateNotyClient: {
      handler() {
        this.updateShipment(this.updateNotyClient)
        // this.updateShipment(this.updateNotyClient);
      }
    },
    'this.shipment.masterbl.shippingLine.supplierId': {
      handler() {
        this.getBookings()
      }
    },
    'shipment.masterBl.houseBls': {
      handler(){
        console.log('cambie houseBls')
        if (this.justChanged){
          console.log('primero')
         // this.justChanged = false;
        } else {
            // eslint-disable-next-line no-debugger
            // debugger;
          if (this.isCon){
            if (this.shipment.masterBl.houseBls[this.indexHouse].mFirst){
              return;
            } else {
              if (this.shipment.masterBl.houseBls[this.indexHouse].mSearch === '' || this.shipment.masterBl.houseBls[this.indexHouse].mSearch === null) return;
              this.searchDebounce3(this.shipment.masterBl.houseBls[this.indexHouse].mSearch)
            }
          }
          if (!this.isCon) {
            if (this.shipment.masterBl.houseBls[this.indexHouse].cFirst){
              return;
            } else {
              if (this.shipment.masterBl.houseBls[this.indexHouse].cSearch === '' || this.shipment.masterBl.houseBls[this.indexHouse].cSearch === null ) return;
              console.log('entre a este')
              this.searchDebounce4(this.shipment.masterBl.houseBls[this.indexHouse].cSearch)
            }
          }
        }
      },
      deep: true
    },
    consigSearch: {
      handler(){
        if (this.consigSearch === '') {
          this.itemsConsig = []
        }
        if (this.itemsConsig.length > 0) return
        this.searchDebounce2(this.consigSearch)
      }
    },
    search:{
      handler() {
        if (this.search === '') {
          this.provedoress = []
        }
        if (this.provedoress.length > 0) return
        this.searchDebounce(this.search)
      },
    },
    'shipment.id': {
      handler(){
        this.txt2select = false
        console.log('cambio shipment.id')
        this.justChanged = true
        //Aqui se limpia el auto de embarcador y consig
        this.shipin = null
        this.provedoress = []
        this.consig = null
        this.itemsConsig = []
        this.searchCase()
        this.itemsCarga = this.shipment.cargo 
        if(this.itemsCarga.length == 0) this.addCargo()
        this.itemsCarga[0].containerNumber = this.shipment.cargo.filter(x => x.containerNumber).map(x => x.containerNumber).join()
        this.itemsCarga[0].containerSeals = this.shipment.cargo.filter(x => x.containerSeals).map(x => x.containerSeals).join()
        this.itemsCarga[0].truckNumber = this.shipment.cargo.filter(x => x.truckNumber).map(x => x.truckNumber).join()
        if (this.shipment.emails !== null){
          let aux = this.shipment.emails.split(';')
          this.shipment.emails = aux
        }
        if (this.shipment.client.contacts){
          this.losNotifys = [];
          this.shipment.client.contacts.forEach((element) => {
            this.losNotifys.push(element.email)
          })
          console.log(this.losNotifys, 'soy los correos we')
        }
        if (this.shipment.masterBl.airline.supplierId) {
          this.getBookings()
        }
        if (this.documentsM !== null) {
          this.getDocuments();
        }
        if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.length === 0){
          console.log('jjasoynulowe')
        } else {
          this.documentsH = this.shipment.masterBl.houseBls[0].documents
          this.getDocuments();
        }
        //Aqui se tendrian que limpiar embarcardor y consig de house

      }
    },
    shipment: {
      handler() {
        if (this.shipment.same === true) {
          this.searchCase()
          this.shipment.same = false
        }
        if (this.isGuardar){
          this.searchCase()
          this.isGuardar = false
        }
        if (this.shipment.emails !== null && typeof this.shipment.emails === 'string'){
          let aux = this.shipment.emails.split(';')
          this.shipment.emails = aux
        }


        //this.getDocuments();
       // console.log(this.shipment.masterBl.shipper.supplier)
        this.trnasId = this.shipment.transportationId
        console.log(this.trnasId)
        this.filterItemsNaviera()
        this.itemsCarga = this.shipment.cargo
        if(this.itemsCarga.length == 0) this.addCargo()
        this.itemsCarga[0].containerNumber = this.shipment.cargo.filter(x => x.containerNumber).map(x => x.containerNumber).join()
        this.itemsCarga[0].containerSeals = this.shipment.cargo.filter(x => x.containerSeals).map(x => x.containerSeals).join()
        this.itemsCarga[0].truckNumber = this.shipment.cargo.filter(x => x.truckNumber).map(x => x.truckNumber).join()
        this.documentsM = this.shipment.masterBl.documents
        if (this.documentsM !== null) {
          this.getDocuments();
        }
        this.services = this.shipment.services
        let length = this.services.length
        this.services = this.services.map((element, index) => {
          return {
            ...element,
            isFirst: index === 0,
            isLast: index === length - 1,
          }
        })
        if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.length === 0){
          console.log('jjasoynulowe')
        } else {
          this.documentsH = this.shipment.masterBl.houseBls[0].documents
          this.getDocuments();
        }
      },
      deep: true,
    },
    dataCargo: {
      handler() {
        console.log('entro datacargo')
        console.log(this.dataCargo.quantity)
        console.log(this.dataCargo.containerNumber)
        if (Array.isArray(this.dataCargo.containerNumber)){
          if (this.dataCargo.quantity < this.dataCargo.containerNumber.length) {
            this.dataCargo.containerNumber.splice(-1)
          }
        }
        if (Array.isArray(this.dataCargo.containerSeals)) {
          if (this.dataCargo.quantity < this.dataCargo.containerSeals.length) {
            this.dataCargo.containerSeals.splice(-1)
          }
        }
        if (Array.isArray( this.dataCargo.truckNumber)){
          if (this.dataCargo.quantity < this.dataCargo.truckNumber.length) {
            this.dataCargo.truckNumber.splice(-1)
          }
        }

      },
      deep: true
    }
    // 'shipment.shippingStatusId': function (newVal,oldVal) {
    //   if(newVal < 2){
    //     this.$toast.error('El embarque ya esta creado no puede regresar a provisional.');
    //     console.log(oldVal)
    //     this.shipment.shippingStatusId = oldVal;
    //   }
    // }
  },

  length(val) {
    this.tab = val - 1;
  },
  computed: {
    timeline() {
      return this.events.slice().reverse();
    },
    currentShipingStatus: function () {
      return this.shippingStatus.find(
          (status) => this.shipment.shippingStatusId === status.id
      );
    },
    totalCompras: function () {
      return this.demoCargos.reduce((prev, next) => {
        return prev + parseInt(next.compra);
      }, 0);
    },
    totalVentas: function () {
      return this.demoCargos.reduce((prev, next) => {
        return prev + parseInt(next.venta);
      }, 0);
    },
    totalProfit: function () {
      return {
        profit: Math.abs(this.totalVentas - this.totalCompras),
        isPositive: this.totalVentas > this.totalCompras,
      };
    },
  },
  mounted() {
    this.getIncoterms()
    this.getOperators();
    this.getIncoterms();
    this.getSuppliers();
    this.getForwarders();
    this.getCustomers();
    this.getCurrencies();
    this.getAgents();
    this.getContainers();
    this.getServices();
    /*if (this.shipment.masterbl.shippingLine.supplierId) {
      alert('jirve')
      this.getBookings()
    }*/
  },
  methods: {
    getHeaders(transportationcategoryId){
      if(transportationcategoryId === 11) return this.headersMaritimo
      if(transportationcategoryId === 9) return this.loadingHeadersPallesM
      if(transportationcategoryId === 1) return this.loadingHeadersPallesT
      if(transportationcategoryId === 2 || transportationcategoryId === 3) return this.loadingHeadersT
    },
    getBookings() {
     // let index = this.suppliers.findIndex((x) => x.id === this.shipment.masterBl.shippingLine.supplierId)
      this.$http.get(`Shipping/booking/ranges/available/?supplierId=${this.shipment.masterBl.airline.supplierId}`).then((response) => {
        this.BooginsRanges = response.data.data;
        if (this.shipment.masterBl.refNumber !== null) {
          this.BooginsRanges.push(this.shipment.masterBl.refNumber)
        }
      });
    },

    emOrCon(index, type){
      if (type === 1) {
        console.log('entre a emOrCon')
        this.indexHouse = index
        this.isCon = true
        this.justChanged = false
      }
      if (type === 2) {
        this.indexHouse = index
        this.isCon = false
        this.justChanged = false
      }
    },
    searchCase(){
      console.log('entre en searchCase')
      if (this.shipment.masterBl.shipper){
        if (this.shipment.masterBl.shipper.clientBuyerId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'clientBuyer'
        }
        if (this.shipment.masterBl.shipper.clientSupplierId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'clientSupplier'
        }
        if (this.shipment.masterBl.shipper.forwarderId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'forwarder'
        }
        if (this.shipment.masterBl.shipper.supplierId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'supplier'
        }
        if (this.shipment.masterBl.shipper.clientId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'Client'
        }
      }
      if (this.shipment.masterBl.consignee) {
        if (this.shipment.masterBl.consignee.supplier.type === null){
          this.itemsConsig = []
          this.consig = null
          this.typeCon = ''
        } else {
          if(this.shipment.masterBl.consignee.clientBuyerId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'clientBuyer'
          }
          if(this.shipment.masterBl.consignee.clientSupplierId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'clientSupplier'
          }
          if(this.shipment.masterBl.consignee.forwarderId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'forwarder'
          }
          if(this.shipment.masterBl.consignee.supplierId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'supplier'
          }
          if(this.shipment.masterBl.consignee.clientId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'Client'
          }
        }
    }
      this.shipment.masterBl.houseBls.forEach((element, index) => {
        console.log(Object.entries(element.shipper).length === 0, 'beforefor');
        if ((Object.entries(element.shipper).length === 0) === false) {
          console.log('entre al foreach')
          if (element.shipper.clientBuyerId) {
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.clientBuyerId
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.indexHouse = index
          }
          if (element.shipper.clientSupplierId) {
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.clientSupplierId
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.indexHouse = index
          }
          if (element.shipper.forwarderId) {
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.forwarderId
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.indexHouse = index
          }
          if (element.shipper.supplierId) {
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.supplierId
            console.log(element.shipper.supplier)
            console.log(element.shipper.supplierId)
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.shipper.supplierId) {
            console.log('entre a supplierId')
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.supplierId
            console.log(element.shipper.supplier)
            console.log(element.shipper.supplierId)
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.shipper.clientId !== null) {
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.clientId
            this.indexHouse = index
            this.justChanged = true
          }
        }
        if (element.consignee) {
          if (element.consignee.clientId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.clientId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.forwarderId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.forwarderId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.supplierId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.supplierId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.clientSupplierId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.clientSupplierId
            this.indexHouse = index
            this.justChanged = true
          }
        }
      })
    },
    searchDebounce4: debouncePromise(function (search) {
      if (search === '') return
      this.getAllSupplyers4(search)
    }, 1000),
    searchDebounce3: debouncePromise(function (search) {
      if (search === '') return
      this.getAllSupplyers3(search)
    }, 2000),
    searchDebounce: debouncePromise(function (search) {
        this.getAllSupplyers(search)
    }, 1000),
    searchDebounce2: debouncePromise(function (search) {
        this.getAllSupplyers2(search)
    }, 1000),
    getAllSupplyers4(search){
      console.log(search)
      // if (this.shipment.masterbl.houseBls[this.indexHouse].cSearch === '') return
      this.shipment.masterBl.houseBls[this.indexHouse].cFirst = true
      this.shipment.masterBl.houseBls[this.indexHouse].cItems = []
      this.$http.get('Suppliers/all/search', {
        params: {
          businessPartnerName: this.shipment.masterBl.houseBls[this.indexHouse].cSearch
        }
      }).then((response) => {
        console.log(response.data.data)
        let aux = response.data.data
        console.log(aux)
        if (aux === null){
          console.log(aux)
          this.shipment.masterBl.houseBls[this.indexHouse].cFirst = false
        } else {
          aux = aux.map((element) => {
            return {
              ...element,
              companyName: element.name,
            }
          })
          aux.forEach((element) => {
            console.log(element)
            this.shipment.masterBl.houseBls[this.indexHouse].cItems.push(element)
          })
        }

      })
    },
    getAllSupplyers3(search){
      console.log(search, 'supplier')
      // if (this.shipment.masterbl.houseBls[this.indexHouse].mSearch === '') return
      this.shipment.masterBl.houseBls[this.indexHouse].mFirst = true
      this.shipment.masterBl.houseBls[this.indexHouse].mItems = []
      this.$http.get('Suppliers/all/search', {
        params: {
          businessPartnerName: this.shipment.masterBl.houseBls[this.indexHouse].mSearch
        }
      }).then((response) => {
        console.log(response.data.data)
        let aux = response.data.data
        console.log(aux)
        if (aux === null) {
          console.log(aux)
        } else {
          aux = aux.map((element) => {
            return {
              ...element,
              companyName: element.name,
            }
          })
          aux.forEach((element) => {
            console.log(element)
            this.shipment.masterBl.houseBls[this.indexHouse].mItems.push(element)
          })
        }

      })
    },
    getAllSupplyers2(search){
      this.itemsConsig = []
      this.$http.get(`Suppliers/all/search`,{
            params: {
              businessPartnerName: search,
            }
          }
      ).then((response) => {
        console.log(response)
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.itemsConsig.push(element)
          })
        }

        console.log(this.provedoress)
        console.log(this.items)
      })
    },
    getAllSupplyers(search){
      this.provedoress = []
      this.$http.get(`Suppliers/all/search`,{
        params: {
          businessPartnerName: search,
        }
          }
      ).then((response) => {
        console.log(response)
        console.log(response)
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.provedoress.push(element)
          })
          console.log(this.provedoress)
          console.log(this.items)
        }
      })
    },
    cleanConsig(){
      this.itemsConsig = []
    },
    cleanEmbarcadorH2(index){
      this.shipment.masterBl.houseBls[index].cItems = []
      this.shipment.masterBl.houseBls[index].cFirst = false
      this.indexHouse = index
      this.isCon = false
      this.justChanged = false
    },
    cleanEmbarcadorH(index){
      this.shipment.masterBl.houseBls[index].mItems = []
      this.shipment.masterBl.houseBls[index].mFirst = false
      this.indexHouse = index
      this.isCon = true
      this.justChanged = false
    },
    cleanEmbarcador(){
      this.provedoress = []
    },




    formatServices(){
      let length = this.services.length
      this.services.forEach((element, index) => {
      element.isFirst = index === 0 ;
      element.isLast = index === length -1;
      })
    },
    deleteServicio(item){
      this.$store.dispatch("working", true)
      let obj = [
        {
          id: item.id,
          status: item.status,
          shippingId: this.shipment.id,
          serviceId: item.service.id,
          isConfirmed: item.isConfirmed,
          documents: null,
        }
      ]
      console.log(obj, 'elobj')
      this.$http.delete(`Shipping/${this.shipment.id}/services`, {data: obj}).then((response) => {
        console.log(response)
        if (response.data.code !== 200){
          this.$toast.error("Algo salio mal");
        } else {
          this.$toast.info("Servicio eliminado con exito!");
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        let item = {
          id: this.shipment.id
        }
        this.$emit('updateCargo', item);
        this.$store.dispatch("working", false)
      })
      this.formatServices()
    },

    deleteDoc(item, open){
      this.$store.dispatch("working", true)
      console.log(item)
      console.log(open)
      let type = null;
      this.fileStructure[0].children.forEach((element) => {
        element.children.forEach((doc) => {
          if (doc.id === item.id){
            type = element.fileName
          }
        })
      })
      console.log(type)
      if (type === "MasterBL") {
        this.$http.delete(`shipping/${this.shipment.id}/master/${this.shipment.masterBl.id}/doc/${item.id}`).then((response) => {
        console.log(response)
        this.$toast.info("Documento eliminado con exito!");
      }).catch((error) => {
        console.log(error)
        this.$toast.info("Algo salio mal, intetelo de nuevo mas tarde");
      }).finally(() => {
       /* this.fileStructure[0].children[0].children.forEach((element, index) => {
          if (item.id === element.id) {
            this.fileStructure[0].children[0].children.splice(index, 1)
          }
        })*/
        let item = {
          id: this.shipment.id
        }
        setTimeout(() => {
          this.$emit('updateCargo', item);
        }, 1500)
        this.$store.dispatch("working", false)
      })
      }
      else {
        this.$http.delete(`shipping/${this.shipment.id}/house/${this.shipment.masterBl.houseBls[0].id}/doc/${item.id}`).then((response) => {
          console.log(response)
          this.$toast.info("Documento eliminado con exito!");
        }).catch((error) => {
          console.log(error)
          this.$toast.info("Algo salio mal, intetelo de nuevo mas tarde");
        }).finally(() => {
          /*this.fileStructure[0].children[1].children.forEach((element, index) => {
            if (item.id === element.id) {
              this.fileStructure[0].children[1].children.splice(index, 1)
            }
          })*/
          let item = {
            id: this.shipment.id
          }
          setTimeout(() => {
            this.$emit('updateCargo', item);
          }, 1500)
          this.$store.dispatch("working", false)
        })
      }



      /*this.$http.delete(`Shipping/${this.shipment.id}/master${this.shipment.masterBl.id}/doc/${item}`, {data: obj}).then((response) => {
        console.log(response)
        if (response.data.code !== 200){
          this.$toast.error("Algo salio mal");
        } else {
          this.$toast.info("Servicio eliminado con exito!");
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        let item = {
          id: this.shipment.id
        }
        this.$emit('updateCargo', item);
        this.$store.dispatch("working", false)
      })*/
    },



    addServicio(){
      let obj = {
        documents: null,
        id: 0,
        isConfirmed: false,
        serviceId: 0,
        shippingId: this.shipment.id,
        isFirst: false,
        isLast: true
      }
      this.services.push(obj);
      this.formatServices()
    },
    getServices(){
      this.$http.get("Services").then((response) => {
        this.itemsServicios = response.data.data
      })
    },
    filterItemsNaviera() {
      this.itemsNaviera = []
      console.log('filteritemsnaviea')
      this.navieraReady = false
      this.shipment.masterBl.additionalInfo.forEach((element) => {
        console.log(element.supplier)
        this.itemsNaviera.push(element.supplier)
      })
      setTimeout(() => {
        let arr = []
        this.itemsNaviera.push(arr)
        this.itemsNaviera.splice(-1)
      }, 1000)
      console.log(this.itemsNaviera)
      this.navieraReady = true
    },
    formatConteiner() {
      let conteiner = this.dataCargo.containerNumber.join(';');
      console.log(conteiner)

    },

    formatSellos() {
      let sellos = this.dataCargo.containerSeals.join(';');
      console.log(sellos)

    },

    okay() {
      console.log(this.shipment)
    },
    remove(item, index) {
      console.log(item)
      if (item.id === 0) {
        this.itemsCarga.splice(index, 1)
      } else {
        this.$http
            .delete(`Shipping/${this.shipment.id}/cargo/${item.id}`, null)
            .then((response) => {
              console.log(response.data.code)
              if (response.data.code === 200) {
                this.$toast.info("Cargo eliminado con exito");
                let cargo = this.shipment.cargo;
                cargo.forEach((element, index) => {
                  if (element.id === item.id) {
                    cargo.splice(index, 1);
                  }
                })
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$store.commit("reset");
                this.$toast.error("La sesion expiro");
                this.$router.replace("/login");
              }
            })
            .finally(() => {
              this.$store.dispatch("working", false)
            });
      }

    },
    addCargo() {

      let addRow = {
        id: 0,
        shippingId: this.shipment.id,
        containerId: null,
        palletsPerContainer: null,
        piecesPerPallet: null,
        cargoType: null,
        volumen: 0,
        containerNumber: null,
        containerSeals: null,
        truckNumber: null,
        weight: 0,
        quantity: 1,
        large: 0,
        width: 0,
        height: 0,
        description: null,
        weightUnitMeasure: "",
        weightPerUnit: null,
        volumetricWeight: null,
        totalWeight: true,
        landFull: null,
        loadingTypeId: null,
        stackable: true,
        tiltable: true,
        dangerous: false,
        temperature: null,
        dangerSubdivisionId: null,
        unitMeasure: "Mts",
      };
      this.itemsCarga.push(addRow)
    },


    addTRow() {
      let addRow = {
        supplierId: null,
        numCaja: null,
        numPlaca: null,

      };

      this.shipment.cargo.push(addRow);
      console.log(addRow);
    },
    validateType(extension) {
      if (extension === 'jpg' || extension === 'jpeg') {
        return 'image/jpeg'
      }
      if (extension === 'png') {
        return 'image/png'
      }
      if (extension === 'gif') {
        return 'image/gif'
      }
      if (extension === 'pdf') {
        return 'application/pdf'
      }
      if (extension === 'doc') {
        return 'application/msword'
      }
      if (extension === 'docx') {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
      if (extension === 'xls') {
        return 'application/vnd.ms-excel'
      }
      if (extension === 'xlsx') {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
      if (extension === 'txt') {
        return 'text/plain'
      }
    },
    downloadDocument(data) {
      let tipo = null;
      console.log(data)
      this.fileStructure.forEach(element => {
        element.children.forEach(item => {
          item.children.forEach(document => {
            if (document.id == data.id) {
              if (!tipo) {
                tipo = item.fileName;
              }
            }
          })
        })
      })

      if (tipo == 'Documentos Embarque') {
        this.$store.dispatch('working', true);
        this.$http.get('Utils/Documents/Download', {
          params: {
            path: data.path
          }
        }).then( async (response) => {
          fetch(response.data)
              .then(response => response.blob())
              .then(blob => {
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = data.fileName + data.fileExtension; // Change this to the desired filename
                downloadLink.style.display = 'none';

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              })
              .catch(error => {
                console.error('Error fetching or downloading the image:', error);
              });
        }).finally(() => {
          this.$store.dispatch('working', false);
        })
      }

      if (tipo == "MasterBL") {
        this.$store.dispatch('working', true);
        this.$http({
          url: `Shipping/${this.shipment.id}/master/${this.shipment.masterBl.id}/doc/${data.id}`,
          method: 'GET',
          responseType: 'blob', // important
        }).then(response => {
          console.log(response)
          console.log(data)
          fileDownload(response.data, `${data.fileName}.${data.fileExtension}`);
          this.$toast.info('Documento descargado');
          // this.$toast.info('Pdf Generado');
          // var file = new Blob([response.data], {type: 'application/pdf'});
          // var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        }).catch(error => {
          this.$toast.error("Algo salio mal, intente mas tarde")
          console.log(error);
        }).finally(() => {
          this.$store.dispatch('working', false);
        })
      }


      if (tipo == "HouseFolder") {
        this.$store.dispatch('working', true);
        this.$http({
          url: `Shipping/${this.shipment.id}/house/${data.shippingHblId}/doc/${data.id}`,
          method: 'GET',
          responseType: 'blob', // important
        }).then(response => {
          fileDownload(response.data, `${data.fileName}.${data.fileExtension}`);
          this.$toast.info('Documento descargado');
          // this.$toast.info('Pdf Generado');
          // var file = new Blob([response.data], {type: 'application/pdf'});
          // var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        }).catch(error => {
          this.$toast.error("Algo salio mal, intente mas tarde")
          console.log(error);
        }).finally(() => {
          this.$store.dispatch('working', false);
        })
      }


    },
    openDialogMedidas(item) {
      console.log(item)
      this.dataCargo = item
      this.medidasDialog = true
    },
    descriptionDialog(data) {
      this.dataCargo = data;
      this.descriptionLoadingDialog = true;
    },
    ContendorFimc(data) {
      console.log(data)
      this.dataCargo = data;
      this.ContendorDialog = true;
    },
    TruckFimc(data) {
      console.log(data)
      this.dataCargo = data;
      this.TruckDialog = true;
    },
    GuiasModal(data) {
      console.log(data)
      this.dataCargo = data;
      this.GuiasDialog = true;
    },

    SellosFimc(data) {
      console.log(data)
      this.dataCargo = data;
      this.sellosDialog = true;
    },

    addTab() {
      this.shipment.masterBl.houseBls.push({
        shippingMblId: 0,
        id: 0,
        mEmbarcador: null,
        mSearch: null,
        mItems: [],
        mFirst: false,

        cFirst: false,
        cConsig: null,
        cSearch: null,
        cItems: [],
        shipper: {
          forwarderId: null,
          supplierId: null,
        },
        consignee: {
          forwarderId: null,
          clientId: null,
        },
        blTypeId: null,
        bookingNumber: null,
        customAgent: null,
        showAsAgreed: false,
        customAgentId: null,
      });
      this.justChanged = false
      // this.$nextTick(() => (this.active = this.tabs.length-1));
    },

    getDocuments() {
      this.fileStructure = [
        {
          fileName: "Documentos",
          children: [],
        },
      ];
      if (this.shipment.documents.length > 0) {
        let docsEmbarque = {
          fileName: 'Documentos Embarque',
          children: [],
        }
        this.shipment.documents.forEach((element) => {
          docsEmbarque.children.push(element)
        })

        this.fileStructure[0].children.push(docsEmbarque)
      }

      if (this.shipment.masterBl.id != 0) {
        let masterFolder = {
          fileName: "MasterBL",
          children: [],
        };
        console.log(this.documentsM)
        if (this.documentsM){
          this.documentsM.forEach((element) => {
            masterFolder.children.push(element);
          });
          console.log(masterFolder)

          this.fileStructure[0].children.push(masterFolder);
        }


        let HouseFolder = {
          fileName: "HouseFolder",
          children: [],
        };
        if (this.documentsH) {
          this.documentsH.forEach((element) => {
            HouseFolder.children.push(element);
          });

          this.fileStructure[0].children.push(HouseFolder);
        }

      }
    },

    finalizar() {
      this.final = true;
      this.$emit("nextStepC", true);
    },

    removeTab(index) {
      this.$store.dispatch("working", true);
      if (this.shipment.masterBl.houseBls.length >= 1) {
        let houseBl = this.shipment.masterBl.houseBls[index];
        if (houseBl.id > 0) {
          this.$http
              .delete(`Shipping/${this.shipment.id}/house/${houseBl.id}`)
              .then((response) => {
                console.log(response.data.data);
                this.$toast.info("Borrado con exito!");
              })
              .catch((error) => {
                console.log(error);
                this.$toast.error("Algo salio mal, contacte al administrador!");
              })
              .finally(() => {
                this.$store.dispatch("working", false);
              });
        }
        this.shipment.masterBl.houseBls.splice(index, 1);
        this.$store.dispatch("working", false);
      }
    },

    actualizrCompra() {
      this.$store.dispatch("working", true);
      let actualizationObject = JSON.parse(
          JSON.stringify(this.prices.purchase)
      );
      delete actualizationObject.createdAt;
      delete actualizationObject.updatedAt;
      delete actualizationObject.status;

      actualizationObject.details.forEach((element) => {
        delete element.supplier;
        element.fees.forEach((fee) => {
          delete fee.currency;
          delete fee.container;
        });
      });
      this.$http
          .put(
              "Shipping/" + this.prices.purchase.id + "/purchase",
              actualizationObject
          )
          .then((response) => {
            console.log(response.data.data);
            this.$toast.info("Borrado con exito!");
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal, contacte al administrador!");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },

    agregar(fee) {
      let precio = this.prices.purchase.details.find(
          (item) => item.id === fee.purchaseDetailId
      );
      console.log(precio);
      console.log(this.prices.purchase.details);
      precio.fees.push({
        containerId: null,
        palletsPerContainer: null,
        piecesPerPallet:null,
        currencyId: null,
        hasRet: false,
        hasVat: false,
        name: "cargo",
        price: 0,
        purchaseDetailId: precio.id,
        quantity: 0,
        ratableWeight: 0,
        status: false,
        total: 0,
      });
    },

    UploadFile: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    ConvertFile: async function () {
    },

    ConvertFile2: async function (index) {
      let documents = this.houseFiles;
      this.temporaryDocs = []

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.temporaryDocs.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
          indexHouse: index
        });
      }
    },

    housDoc() {
      this.$store.dispatch("working", true);
      this.$http
          .get(
              `Shipping/${this.shipment.id}/house/${this.shipment.masterBl.houseBls[0].id}/doc/${this.shipment.masterBl.houseBls[0].documents[0]}`
          )
          .then((response) => {
            if (response.data.code == 200) {
              this.Currencies = response.data.data;
              this.$toast.info("Operacion realizada con exito!");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal, contacte al administrador!");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    masterDoc() {
      this.$store.dispatch("working", true);
      this.$http
          .get(
              `Shipping/${this.shipment.id}/house/${this.shipment.masterBl.id}/doc/${this.shipment.masterBl.documents[0]}`
          )
          .then((response) => {
            if (response.data.code == 200) {
              this.Currencies = response.data.data;
              this.$toast.info("Operacion realizada con exito!");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal, contacte al administrador!");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },

    eliminar(fee, index) {
      console.log(fee);
      let precio = this.prices.purchase.details.find(
          (item) => item.id === fee.purchaseDetailId
      );
      precio.fees.splice(index, 1);
    },
    setCurrency(index) {
      this.prices[index].priceFees.forEach((element) => {
        element.currencyId = this.currencyId;
      });
      this.mapAndUpdatePrice(index);
    },
    getCurrencies() {
      this.$store.dispatch("working", true);
      this.$http.get("currencies").then((response) => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
        }
      });
    },
    getConsignees() {
      this.$store.dispatch("working", true);
      this.$http.get("Suppliers/Consignees").then((response) => {
        if (response.data.code == 200) {
          this.Consignees = response.data.data;
        }
      });
    },

    guardadr() {
      console.log(this.masterBl);
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
              .split(" ")
              .map((v) => v.charAt(0))
              .join("")}`;
        }),
      });

      this.input = null;
    },
    //this.shipment.incoterm = item.Incoterm.code;
    setCatalog() {
      console.log(this.shipin)

      if(this.shipin.type == "Supplier"){
        this.shipment.masterBl.shipper.supplierId =  this.shipin.id
        this.shipment.masterBl.shipper.supplier =  this.shipin.name
        console.log(this.shipment.masterBl.shipper)
      }else if(this.shipin.type == "ClientSupplier"){
        this.shipment.masterBl.shipper.supplierId =  this.shipin.id
      }
    },
    setEm(shipment){
      console.log('setEm')
      if (this.shipin === null){
        return shipment
      }
      if (typeof this.shipin === 'number'){
        if (this.typeEM === 'supplier'){
          shipment.masterbl.shipper = {
            supplierId: this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'forwarder') {
          shipment.masterbl.shipper = {
            forwarderId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'clientSupplier') {
          shipment.masterbl.shipper = {
            clientSupplierId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'clientSupplier') {
          shipment.masterbl.shipper = {
            clientSupplierId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'clientBuyer') {
          shipment.masterbl.shipper = {
            clientBuyerId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'Client') {
          shipment.masterbl.shipper = {
            clientId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === ''){
          shipment.masterbl.shipper = {}
          return shipment
        }
        return shipment
      }
      if (typeof this.shipin === 'object'){
        console.log('object')
        if (this.shipin.type === 'ClientSupplier'){
          shipment.masterbl.shipper = {
            clientSupplierId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'Forwarder'){
          shipment.masterbl.shipper = {
            forwarderId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'Supplier'){
          shipment.masterbl.shipper = {
            supplierId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'ClientBuyer'){
          shipment.masterbl.shipper = {
            clientBuyerId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'Client') {
          console.log('Client')
          console.log(this.shipin.id)
          shipment.masterbl.shipper = {
            clientId:  this.shipin.id
          }
          console.log(shipment)
          return shipment
        }
        if (this.shipin.type === ''){
          shipment.masterbl.shipper = {}
          return shipment
        }
      }
      return shipment
    },
    setCon(shipment){
      console.log(typeof this.consig, shipment)
      if (this.consig === null){
        return shipment
      }
      if (typeof this.consig === 'number'){
        if (this.typeCon === 'supplier'){
          shipment.masterbl.consignee = {
            supplierId: this.consig
          }
          return shipment
        }
        if (this.typeCon === 'forwarder') {
          shipment.masterbl.consignee = {
            forwarderId:  this.consig
          }
          return shipment
        }
        if (this.typeCon === 'clientSupplier') {
          shipment.masterbl.consignee = {
            clientSupplierId:  this.consig
          }
          return shipment
        }
        if (this.typeCon === 'Client') {
          shipment.masterbl.consignee = {
            clientId:  this.consig
          }
          return shipment
        }
        /*if (this.consig.type === 'Client'){
          shipment.masterbl.consignee = {
            clientId: this.consig.id
          }
          return shipment
        }*/
        if (this.typeCon === 'clientBuyer') {
          shipment.masterbl.consignee = {
            clientBuyerId:  this.consig
          }
          return shipment
        }
        if (this.consig === ''){
          shipment.masterbl.consignee = {}
          return shipment
        }
        return shipment
      }
      if (typeof this.consig === 'object'){
        if (this.consig === null) return shipment
        if (this.consig.type === 'ClientSupplier'){
          shipment.masterbl.consignee = {
            clientSupplierId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'Forwarder'){
          shipment.masterbl.consignee = {
            forwarderId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'Supplier'){
          shipment.masterbl.consignee = {
            supplierId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'ClientBuyers'){
          shipment.masterbl.consignee = {
            clientBuyerId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'Client'){
          shipment.masterbl.consignee = {
            clientId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === ''){
          shipment.masterbl.consignee = {}
          return shipment
        }
      }
      return shipment
    },
    setEmbH(embarcador){
      return embarcador
    },
    async saveDocs () {
      let documents = this.masterFiles;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.shipment.masterBl.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
        });
      }
      this.masterFiles = []
    },
    async saveDocsHouse () {
      this.temporaryDocs.forEach((element) => {
        this.shipment.masterBl.houseBls[element.indexHouse].documents.push(element)
      })
      this.houseFiles = []
    },
    async updateShipment(toUpdate) {
      console.log("shipment: ", this.shipment);
      await this.saveDocs();
      await this.saveDocsHouse();
      if(this.shipment.transportationId == 1){
        let updateShiping = {
          landTransfer: this.shipment.landTransfer,
          users: this.shipment.users,
          externalRefNumber: this.shipment.externalRefNumber,
          sendNotificationsToClient: this.shipment.sendNotificationsToClient,
          industry: this.shipment.industry,
          BusinessId: this.shipment.businessId,
          deliveryTypeId: this.shipment.deliveryType.id,
          orderTypeId: this.shipment.orderTypeId,
          id: this.shipment.id,
          IncotermId: this.shipment.incotermId,
          ClientId: this.shipment.client.id,
          TransportationId: this.shipment.transportationId,
          transportationcategoryId: this.shipment.transportationcategoryId,
          ClientSupplierName: "Na", // Donde esta esto?
          ReferenceNumber: this.shipment.referenceNumber,
          commodity: this.shipment.commodity,
          ShippingStatusId: this.shipment.shippingStatusId,
          ContactName: "Na", //Donde esta esto?
          mails: this.shipment.emails ? this.shipment.emails.join(';') : null,
          Comments: "Na", //Donde esta esto?
          ChangeTypeId: 2, // que es esto?
          QuotationId: this.shipment.quotationId,
          services: this.services,
          masterbl: {
            id: this.shipment.masterBl.id ?? 0,
            shipper: {
            },
            consignee: {
            },
            shippingLine: {
              supplierId: this.shipment.masterBl.shippingLine.supplierId,
            },
            documents: this.shipment.masterBl.documents,
            supplierId: this.shipment.masterBl.supplierId, //MasterBL
            bookingNumber: this.shipment.masterBl.bookingNumber,
            refNumber: this.shipment.masterBl.refNumber,
            shipName: this.shipment.masterBl.shipName, //Nombre de barco
            tripNumber: this.shipment.masterBl.tripNumber, //Nombre de viaje
            storageDays: this.shipment.masterBl.storageDays,
            delaysInPort: this.shipment.masterBl.delaysInPort,
            additionalInfo: this.shipment.masterBl.additionalInfo,
            houseBls: this.shipment.masterBl.houseBls,
            freeDays: this.shipment.masterBl.freeDays
          },
        };
        updateShiping = this.setEm(updateShiping)
        updateShiping = this.setCon(updateShiping)
        console.log(updateShiping)
        // console.log(updateShiping.masterbl)
        if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.lenght === 0){
          console.log('nada')
        } else {
          updateShiping.masterbl.houseBls.forEach((element, index) => {
            // todo esto deberia estar en una funcion xd
            if (element.cConsig !== null){
              if (element.cConsig.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientSupplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
                console.log(updateShiping.masterbl.houseBls[index].consignee)
              }
              if (element.cConsig.type === 'Client') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Forwarder') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.forwarderId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.supplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientBuyerId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
            }
            if (element.mEmbarcador !== null) {
              if (element.mEmbarcador.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientSupplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Forwarder'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.forwarderId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.supplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientBuyerId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Client'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
            }
          })
        }
        if (toUpdate !== null && typeof toUpdate !== 'object') {
          console.log(toUpdate);
          updateShiping.sendNotificationsToClient = toUpdate
        }

        updateShiping.cargo = this.itemsCarga
        this.$store.dispatch("working", true);
        this.$http
            .put(`Shipping/${this.shipment.id}`, updateShiping)
            .then((response) => {
              console.log(response);
              this.prices = response.data.data;
              this.masterFiles = [];
              this.houseFiles = [];
              this.$toast.info("MasterBl Actualizado");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setTimeout(() => {
                let item = {
                  id: this.shipment.id
                }
                this.$emit('updateCargo', item);
              }, 500)
              this.isGuardar = true
              this.$store.dispatch("working", false);

            });

      }
      if(this.shipment.transportationId == 2){
        let updateShiping = {
          landTransfer: this.shipment.landTransfer,
          users: this.shipment.users,
          sendNotificationsToClient: this.shipment.sendNotificationsToClient,
          industry: this.shipment.industry,
          BusinessId: this.shipment.businessId,
          deliveryTypeId: this.shipment.deliveryType.id,
          orderTypeId: this.shipment.orderTypeId,
          id: this.shipment.id,
          IncotermId: this.shipment.incotermId,
          ClientId: this.shipment.client.id,
          TransportationId: this.shipment.transportationId,
          transportationcategoryId: this.shipment.transportationcategoryId,
          ClientSupplierName: "NA", // Donde esta esto?
          ReferenceNumber: this.shipment.referenceNumber,
          commodity: this.shipment.commodity,
          ShippingStatusId: this.shipment.shippingStatusId,
          ContactName: "NA", //Donde esta esto?
          Comments: "NA", //Donde esta esto?
          ChangeTypeId: 2, // que es esto?
          QuotationId: this.shipment.quotationId,
          services: this.services,
          masterbl: {
            id: this.shipment.masterBl.id ?? 0,
            shipper: {
            },
            consignee: {
            },
            airline: {
              supplierId: this.shipment.masterBl.airline.supplierId,
            },
            documents: this.shipment.masterBl.documents,
            supplierId: this.shipment.masterBl.supplierId, //MasterBL
            bookingNumber: this.shipment.masterBl.bookingNumber,
            refNumber: this.shipment.masterBl.refNumber,
            shipName: this.shipment.masterBl.shipName, //Nombre de barco
            tripNumber: this.shipment.masterBl.tripNumber, //Nombre de viaje
            storageDays: this.shipment.masterBl.storageDays,
            delaysInPort: this.shipment.masterBl.delaysInPort,
            additionalInfo: this.shipment.masterBl.additionalInfo,
            houseBls: this.shipment.masterBl.houseBls,
          },
        };
        // mails: this.shipment.emails ? this.shipment.emails.join(';') : null,

        if (typeof this.shipment.emails === "string") {
          updateShiping.mails = this.shipment.emails.join('l')
        }
        updateShiping = this.setEm(updateShiping)
        updateShiping = this.setCon(updateShiping)
        console.log(updateShiping)
        console.log(updateShiping.masterbl)
        if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.lenght === 0){
          console.log('nada')
        } else {
          updateShiping.masterbl.houseBls.forEach((element, index) => {
            // todo esto deberia estar en una funcion xd
            if (element.cConsig !== null){
              if (element.cConsig.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientSupplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
                console.log(updateShiping.masterbl.houseBls[index].consignee)
              }
              if (element.cConsig.type === 'Client') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Forwarder') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.forwarderId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientBuyerId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
            }
            if (element.mEmbarcador !== null) {
              if (element.mEmbarcador.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientSupplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Forwarder'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.forwarderId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.supplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientBuyerId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Client'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
            }
          })
        }
        updateShiping.cargo = this.itemsCarga

        if (toUpdate !== null && typeof toUpdate !== 'object') {
          console.log(toUpdate);
          updateShiping.sendNotificationsToClient = toUpdate
        }
        this.$store.dispatch("working", true);
        this.$http
            .put(`Shipping/${this.shipment.id}`, updateShiping)
            .then((response) => {
              console.log(response);
              this.prices = response.data.data;
              this.masterFiles = [];
              this.houseFiles = [];
              this.$toast.info("MasterBl Actualizado");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setTimeout(() => {
                let item = {
                  id: this.shipment.id
                }
                this.$emit('updateCargo', item);
              }, 1000)
              this.$store.dispatch("working", false);
            });
      }
      if(this.shipment.transportationId == 3){
        let updateShiping = {
          landTransfer: this.shipment.landTransfer,
          sendNotificationsToClient: this.shipment.sendNotificationsToClient,
          users: this.shipment.users,
          industry: this.shipment.industry,
          BusinessId: this.shipment.businessId,
          deliveryTypeId: this.shipment.deliveryType.id,
          orderTypeId: this.shipment.orderTypeId,
          id: this.shipment.id,
          IncotermId: this.shipment.incotermId,
          ClientId: this.shipment.client.id,
          TransportationId: this.shipment.transportationId,
          transportationcategoryId: this.shipment.transportationcategoryId,
          ClientSupplierName: "NA", // Donde esta esto?
          ReferenceNumber: this.shipment.referenceNumber,
          commodity: this.shipment.commodity,
          ShippingStatusId: this.shipment.shippingStatusId,
          ContactName: "this.shipme" + "ntToServer.contactName", //Donde esta esto?
          mails: this.shipment.emails ? this.shipment.emails.join(';') : null,
          Comments: "NA", //Donde esta esto?
          ChangeTypeId: 2, // que es esto?
          QuotationId: this.shipment.quotationId,
          services: this.services,
          masterbl: {
            id: this.shipment.masterBl.id ?? 0,
            shipper: {
            },
            consignee: {
            },
            shippingLine: {
              supplierId: this.shipment.masterBl.shippingLine.supplierId,
            },
            documents: this.shipment.masterBl.documents,
            supplierId: this.shipment.masterBl.supplierId, //MasterBL
            bookingNumber: this.shipment.masterBl.bookingNumber,
            refNumber: this.shipment.masterBl.refNumber,
            shipName: this.shipment.masterBl.shipName, //Nombre de barco
            tripNumber: this.shipment.masterBl.tripNumber, //Nombre de viaje
            storageDays: this.shipment.masterBl.storageDays,
            delaysInPort: this.shipment.masterBl.delaysInPort,
            additionalInfo: this.shipment.masterBl.additionalInfo,
            houseBls: this.shipment.masterBl.houseBls,
          },
        };
        updateShiping = this.setEm(updateShiping)
        updateShiping = this.setCon(updateShiping)
        console.log(updateShiping)
        // console.log(updateShiping.masterbl)
        if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.length === 0){
          console.log('nada')
        } else {
          updateShiping.masterbl.houseBls.forEach((element, index) => {
            // TODO esto deberia estar en una funcion xd
            if (element.cConsig !== null){
              if (element.cConsig.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientSupplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
                console.log(updateShiping.masterbl.houseBls[index].consignee)
              }
              if (element.cConsig.type === 'Client') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Forwarder') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.forwarderId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
            }
            if (element.mEmbarcador !== null) {
              if (element.mEmbarcador.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientSupplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Forwarder'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.forwarderId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.supplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientBuyerId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Client'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
            }
          })
        }
        updateShiping.cargo = this.itemsCarga

        if (toUpdate !== null && typeof toUpdate !== 'object') {
          console.log(toUpdate);
          updateShiping.sendNotificationsToClient = toUpdate
        }

        this.$store.dispatch("working", true);
        this.$http
            .put(`Shipping/${this.shipment.id}`, updateShiping)
            .then((response) => {
              console.log(response);
              this.prices = response.data.data;
              this.masterFiles = [];
              this.houseFiles = [];
              this.$toast.info("MasterBl Actualizado");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setTimeout(() => {
                let item = {
                  id: this.shipment.id
                }
                this.$emit('updateCargo', item);
              }, 500)
              this.isGuardar = true
              this.$store.dispatch("working", false);

            });

      }
    },
    getCustomers: function () {
      this.$store.dispatch("working", true);
      this.$http
          .get("Companies")
          .then((response) => {
            console.log(response);
            this.companies = response.data.data;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit("reset");
              this.$toast.error("La sesion expiro");
              this.$router.replace("/login");
            }
          })
          .finally(() => this.$store.dispatch("working", false));
    },
    supplierChanged() {
      console.log("eNTRO");
      this.shipment.supplier = this.suppliers.find(
          (el) => el.id === this.shipment.supplierId
      );
      this.shipment.price.supplierId = this.shipment.supplierId;
      this.$http.put(`Orders/${this.shipment.orderId}/prices`, [
        this.shipment.price,
      ]);
    },

    updateCargo() {
      this.shipment.cargo.forEach((element, index) => {
        delete element.container
        this.shipment.cargo[index].quantity = Number(this.shipment.cargo[index].quantity)
      })

      /*let index = this.shipment.cargo.findIndex(x => x.id == this.dataCargo.id)
      this.shipment.cargo[index] = this.dataCargo;*/

      let cargo = JSON.parse(JSON.stringify(this.shipment.cargo));

      /*this.shipment.cargo.forEach((element, index) => {
        if (element.containerNumber.length < 0) {
          let aux = element.containerNumber.join(';')
          console.log(aux)
          this.shipment.cargo[index].containerNumber = aux
          this.shipment.cargo[index].containerNumber = null
        }
        if (element.containerSeals.length < 0) {
          let aux = element.containerSeals.join(';')
          console.log(aux)
          this.shipment.cargo[index].containerSeals = aux
        } else {
          this.shipment.cargo[index].containerSeals = null
        }
        if (element.truckNumber.length < 0) {
          let aux = element.truckNumber.join(';')
          console.log(aux)
          this.shipment.cargo[index].truckNumber = aux
        } else {
          this.shipment.cargo[index].truckNumber = null
        }
      })*/

      cargo.forEach((element, index) => {
        if (element.containerNumber){
          if (element.containerNumber.length > 0){
            let aux = element.containerNumber.join(';')
            cargo[index].containerNumber = aux;
          } else {
            cargo[index].containerNumber = null;
          }
        } else {
          cargo[index].containerNumber = null;
        }
        if (element.containerSeals){
          if (element.containerSeals.length > 0){
            let aux = element.containerSeals.join(';')
            cargo[index].containerSeals = aux;
          } else {
            cargo[index].containerSeals = null;
          }
        } else {
          cargo[index].containerSeals = null;
        }
        if (element.truckNumber) {
          if (element.truckNumber.length > 0){
            let aux = element.truckNumber.join(';')
            cargo[index].truckNumber = aux;
          } else {
            cargo[index].truckNumber = null;
          }
        } else {
          cargo[index].truckNumber = null;
        }
      })

      this.$http.put(`Shipping/${this.shipment.id}/cargo`, cargo)
          .then((response) => {
            console.log(response);
            this.ContendorDialog = false;
            this.descriptionLoadingDialog = false;
            this.$toast.info("Guardado con exito");
          }).catch((error) => {
        console.log(error)
        this.$toast.error("Ocurrio un error");
      }).finally(() => {
        this.medidasDialog = false
        let item = {
          id: this.shipment.id
        }
        this.$emit('updateCargo', item);
      })

    },
    updateBultos() {
      this.sellosDialog = false;
      this.shipment.cargo.forEach((element, index) => {
        delete element.container
        this.shipment.cargo[index].quantity = Number(this.shipment.cargo[index].quantity)
      })

      /*let index = this.shipment.cargo.findIndex(x => x.id == this.dataCargo.id)
      this.shipment.cargo[index] = this.dataCargo;*/

      this.shipment.cargo.forEach((element, index) => {
        if (element.containerSeals.length > 0) {
          let aux = element.containerSeals.join(';')
          console.log(aux)
          this.shipment.cargo[index].containerSeals = aux
        } else {
          this.shipment.cargo[index].containerSeals = null
        }
      })

      this.$http.put(`Shipping/${this.shipment.id}/cargo`, this.shipment.cargo)
          .then((response) => {
            console.log(response);
            this.sellosDialog = false;
            this.descriptionLoadingDialog = false;
            this.$toast.info("Guardado con exito");
          }).catch((error) => {
        console.log(error)
        this.$toast.error("Ocurrio un error");
      }).finally(() => {
        let item = {
          id: this.shipment.id
        }
        this.$emit('updateCargo', item);
      })

    },

    updateNum() {
      this.shipment.cargo.forEach((element) => {
        delete element.container
      })
      console.log(this.dataCargo)
      console.log(this.shipment.cargo)
      let cargo = JSON.parse(JSON.stringify(this.dataCargo.containerNumber.join(';')))
      let index = this.shipment.cargo.findIndex(x => x.id === this.dataCargo.id)
      console.log(cargo)
      console.log(index)
      this.shipment.cargo[index].containerNumber = cargo
      this.$http.put(`Shipping/${this.shipment.id}/cargo`, this.shipment.cargo)
          .then((response) => {
            console.log(response);
            this.ContendorDialog = false;
            this.descriptionLoadingDialog = false;
            this.$toast.info("Guardado con exito");
            //fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          });

    },
    confirmCharges() {
      this.chargesWorking = true;
      setTimeout(() => {
        this.chargesWorking = false;
        this.confimedCharges = true;
        this.$toast.info("Embarque actualizado");
        this.shipment.shippingStatusId = 2;
        this.updateShippingStatus();
      }, 2000);
    },

    setFirstLast: function (currItems) {
      // first check that actually exists values
      if (currItems.length) {
        // toggle all to false
        currItems.forEach((x) => {
          x.isLast = false;
          x.isFirst = false;
        });
        // just set first to true
        currItems[currItems.length - 1].isLast = true;
        currItems[0].isFirst = true;
      }
    },
    /* updateShipment(event) {
       console.log("Event", event)
       console.log("Updated");
       this.shipmentWorking = true;
       // Estos de back se pasan...


       setTimeout(() => {
         this.$toast.info("Embarque actualizado.");
         this.shipmentWorking = false;
       }, 3000)
     },*/
    newContainer: debouncePromise(function (event) {
      console.log(event);
      const container = event;
      let dataServer = JSON.parse(JSON.stringify(this.shipmentToServer));
      dataServer.cargo = container;
    }, 1000),
    updateShippingStatus() {
      this.shipmentWorking = true;
      this.$http
          .put(
              `Employee/Groups/groupId`
          )
          .then((response) => {
            this.$refs.statusBar.getCount();
            this.$toast.info("Embarque actualizado.");

            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal, intente mas tarde");
          })
          .finally(() => {
            this.shipmentWorking = false;
          });
    },
    openSolicitudDialog() {
      console.log(this.solicitudDialog);
      this.solicitudDialog = true;
    },
    getIncoterms() {
      this.$http
          .get("Incoterms")
          .then((response) => {
            console.log(response)
            this.incoterms = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getAgents() {
      // this.$store.dispatch('working', true)

      this.$http
          .get("CustomAgents")
          .then((response) => {
            this.CustomAgents = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getContainers() {
      this.itemsContainers = []
      this.$store.dispatch("working", true);
      this.$http("Containers").then((response) => {
        this.itemsContainers = response.data.data;
        console.log('CONTAINEEEEEEEEEEEEEEERS')
        console.log(this.itemsContainers)
        let arr = []
        this.itemsContainers.push(arr);
        this.itemsContainers.splice(-1)
      }).catch((error) => {
        console.log(error);
        this.$toast.error("Algo salio mal, contacte al administrador!");
      }).finally(() => {
        this.$store.dispatch("working", false);
      })

    },

    getSuppliers() {
      // this.$store.dispatch('working', true)

      this.$http
          .get("Suppliers")
          .then((response) => {
            this.suppliers = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    /*SendToMaster:() {
      let infoMaster{}
}
    */

    putMaster() {
      // this.$store.dispatch('working', true)
      this.$http
          .get("Operations/" + this.shipment.id)
          .then((response) => {
            this.operators = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getOperators() {
      // this.$store.dispatch('working', true)

      this.$http
          .get("Employees/operators")
          .then((response) => {
            this.operators = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getForwarders: function () {
      this.$store.dispatch("working", true);
      this.$http
          .get("Suppliers/forwarders")
          .then((response) => {
            if (response.data.code == 200) {
              console.log(response.data.data);
              this.forwarders = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    getPrices() {
      this.$http
          .get(`Orders/${this.IdOrder}/prices`)
          .then((response) => {
            this.prices = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    /*    autosaveShipping: debouncePromise(function (master) {
          this.updateShipment(master);
        }, 5000),*/
  },
};
</script>

<style scoped>
.timeline {
  display: block;
  position: relative;
}

>>> .timeline .v-input__slot {
  padding: 0 6px !important;
}

>>> .timeline .v-input__slot .v-label {
  margin-left: 6px;
}

.wrap {
  margin-left: 21rem;
  margin-right: 3.5rem;
}

.wrap_xl {
  margin-left: 21rem;
  margin-right: 27rem;
}

>>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
}

>>> .v-alert__icon.v-icon {
  margin-top: 6px;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0;
}

>>> .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0px;
}

>>> .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 0px 0 2px;
}
</style>
